module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Blog Atawiz","short_name":"Blog Atawiz","start_url":"/","background_color":"#FBFBFB","theme_color":"#FBFBFB","display":"browser","icon":"src/assets/favicon/favicon-64.png","icons":[{"src":"src/assets/favicon/favicon-192.png","sizes":"192x192","type":"image/png"},{"src":"src/assets/favicon/favicon-160.png","sizes":"160x160","type":"image/png"},{"src":"src/assets/favicon/favicon-96.png","sizes":"96x96","type":"image/png"},{"src":"src/assets/favicon/favicon-64.png","sizes":"64x64","type":"image/png"},{"src":"src/assets/favicon/favicon-32.png","sizes":"32x32","type":"image/png"},{"src":"src/assets/favicon/favicon-16.png","sizes":"16x16","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4d0d2ffc2f994aa9bf2cd63e2a7f0490"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["GTM-5JMCXRH"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
